import { createApp } from 'vue'
import App from './App.vue'
import router from '@/lib/router.js'
// import { DropdownItem, DropDowonMenu } from 'vant';
import {Search, Checkbox, Uploader, Popover, Notify,Field } from 'vant';
import 'vant/lib/index.css';
import VueApexCharts from 'vue3-apexcharts'
import { plugin as Slicksort } from 'vue-slicksort';

const app = createApp(App)
app.provide('imageServerUrl', process.env.VUE_APP_IMAGE_SERVER_URL)
app.provide('benchmarkBaseUrl', process.env.VUE_APP_BENCHMARK_BASE_URL)
app.provide('maxTilesInList', 8)
app.provide('disableNonMVPFeatures', process.env.VUE_APP_DISABLE_NON_MVP1_FEATURES === 'true' ? true : false )
app.provide('disabledFeaturesMessage', "This feature is not available right now")

app.use(router)
// .use(DropdownItem)
// .use(DropdownMenu)
.use(Search)
.use(Checkbox)
.use(Uploader)
.use(VueApexCharts)
.use(Slicksort)
.use(Popover)
.use(Notify)
.use(Field)
.mount('#app')
