import {createRouter, createWebHistory} from 'vue-router'
// import { post } from './api'

const routes = [
    {
        path: '/',
        redirect: '',
        name: 'Login',
        component: () => import('@/views/Login.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('@/views/Register.vue'),
        meta: { requiresAuth: false }
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        children: [
            {
                path: '/benchmarks',
                name: 'Benchmarks',
                component: () => import('@/views/Benchmarks/List.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/benchmarks/create',
                name: 'CreateBenchmark',
                component: () => import('@/views/Benchmarks/Create.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/benchmarks/:id',
                name: 'BenchmarkDetails',
                component: () => import('@/views/Benchmarks/Details.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/benchmarks/update/:id',
                name: 'UpdateBenchmark',
                component: () => import('@/views/Benchmarks/Update.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/devices',
                name: 'Devices',
                component: () => import('@/views/Devices/List.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/devices/add',
                name: 'AddDevice',
                component: () => import('@/views/Devices/Create.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/devices/update/:id',
                name: 'UpdateDevice',
                component: () => import('@/views/Devices/Update.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/results',
                name: 'Results',
                component: () => import('@/views/Results/List.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/results/:id',
                name: 'ResultDetails',
                component: () => import('@/views/Results/Details.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/tests/create',
                name: 'CreateTests',
                component: () => import('@/views/Tests/Create.vue'),
                meta: { requiresAuth: true }
            },
            {
                path: '/tests/update/:id',
                name: 'UpdateTests',
                component: () => import('@/views/Tests/Update.vue'),
                meta: { requiresAuth: true }
            }
        ]
    }
  ]


const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if(to.meta.requiresAuth) {
        const user = JSON.parse(localStorage.getItem('user'))
        if(user && user.tokenExpiredAt){
            const curTimeInMs = (new Date()).getTime();
            const expTimeInMs = (new Date(user.tokenExpiredAt)).getTime()
            if(curTimeInMs < expTimeInMs){
                return next()
            }
        }
        next({path: '/'})
    } else {
        next()
    }
})

export default router

